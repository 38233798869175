.toolbar {
  height: 64px;
  background-color: #fff;
}

.sidenav {
  position: fixed;
  height: calc(100% - 64px); /* subtract toolbar height */
  width: 300px;

  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  
  background-color: #160032;
}

.sidenav h3 {
  padding-left: 16px;
}

.content {
  position: fixed;
  height: calc(100% - 96px); /* subtract toolbar height and 32px margin */
  width: calc(100% - 332px); /* subtract sidenav width and 32px for margin */
  
  margin: 16px;
  left: 300px; /* sidenav width */
  /* background-color: #ffe1b5; */
}

@media screen and (max-width: 1300px) {
  .sidenav {
    width: 60px;
    padding: 0px;
  }
  
  .content {
    position: fixed;
    width: calc(100% - 92px); /* subtract sidenav width and 32px for margin */
    
    margin: 16px;
    left: 60px; /* sidenav width */
    /* background-color: #ffe1b5; */
  }
  
}