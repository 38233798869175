.logo {
  left: 0;
  position: fixed;
  height: 64px;
  width: 300px; /* sidenav width */
  background-color: #160032;
}

.search {
  margin-left: 304px;
  width: 200px;
}

.searchinput {
  min-width: 400px;
}

.menu {
  position: fixed;
  height: 64px;
  width: calc(100% - 300px); /* subtract sidenav width */
  
  left: 300px; /* sidenav width */
  background-color: #ffffff;
}

@media screen and (max-width: 1300px) {
  .logo {
    width: 60px;
  }

  .search {
    margin-left: 64px;
  }

  .searchinput {
    min-width: 300px;
  }

  .menu {
    width: calc(100% - 60px); /* subtract sidenav width */
    left: 60px; /* sidenav width */
  }
}

@media screen and (max-width: 500px) {
  .searchinput {
    min-width: 250px;
  }
}
