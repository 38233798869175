.sidenav {
  color: #fefefe;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: normal;
  padding-top: 8px;
  height: 100%;
}

.sidenav h3 {
  border-bottom: 1px solid rgba(238, 238, 238, 0.3);
  color: #fefefe;
  font-size: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.sidenav_section {
  margin-bottom: 32px;
}

.footer {
  color: #fefefe;
  font-size: 10px;
  padding-bottom: 8px;
  text-align: center;
  bottom: 0;
  width: 100%;
  margin: 0 0 0 -16px;
  position: absolute;
}

.footer p {
  margin: auto;
}

@media screen and (max-width: 1300px) {
  .footer {
    font-size: 8px;
  }
}
