.logo {
    padding-top: 4px;
    padding-left: 24px;
}

.logo img {
    max-height: 48px;
    max-width: 100%;
}

.mini {
    display: none;
    padding-left: 6px;
}

.mini img {
    height: 48px;
}

@media screen and (max-width: 1300px) {
    .logo img {
      display: none;
    }
    .mini {
        display: block;
    }
}
  