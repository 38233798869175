.icon {
  font-size: 14px;
  top: 15px;
}

.title {
  display: inline;
  font-size: 12px;
  padding-left: 20px;
}

.title:hover {
  text-decoration: none;
}

.item {
  margin-bottom: 15px;
  padding-top: 8px;
}

.item a,
.item a:hover {
  color: #f4f4f4;
  text-decoration: none;
}

.href {
  border-radius: 5px;
  color: #ececee;
  padding: 8px;
  margin-left: 4px;
  margin-right: 4px;
  display: block;
}

.href:hover {
  background-color: #e91c24;
  color: #fefefe;
  text-decoration: none;
}

@media screen and (max-width: 1300px) {
  .title {
    display: none;
  }
  .item {
    text-align: center;
    margin-right: 10px;
  }
}